import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment';

import * as AccountActions from '../actions/Accounts';

import { decode, truncate } from '../utils';
import { Link, withRouter } from 'react-router-dom';

import Modal from './Modal';
import { loadContactUsSurvey } from '../utils'

class ShopifyCheckoutExtensionModal extends Component {
  render() {
    if (!this.props.account) { return null; }
    if (!this.props.account.shop) { return null; }
    if (!this.props.user) { return null; }
    if (window.sessionStorage.getItem('presentedCheckoutExtensionModal')) { return null; }
    if (this.props.user.presentedCheckoutExtensionModal) { return null; }

    return (
      <Modal 
        isOpen={this.props.showCheckoutExtensionModal}
        onRequestClose={() => {
          // this.props.hideCheckoutExtensionModal();
        }}
      >
        <div className={`frame checkout-extensions`}>
          <div className="title">Your almost ready to go!</div>
          <div className="content">
            <div>If you are using <a href="https://www.shopify.com/plus/upgrading-to-checkout-extensibility" target="_blank">Checkout Extensibility</a> for your thank you, order status, or accounts pages you need to <a href="https://www.zigpoll.com/blog/zigpoll-shopify-checkout-extensions" target="_blank">add the Zigpoll App Block</a>.</div>

            <div class="video-wrapper active">
            <video loop="loop" muted="" controls="" defaultmuted="" playsinline="" oncontextmenu="return false;" preload="auto" autoplay="autoplay" id="myVideo">
              <source src="https://www.zigpoll.com/img/video/checkoutextensionsetup.mp4" type="video/mp4" />
            </video>
            </div>

            <div><a href="https://www.zigpoll.com/blog/zigpoll-shopify-checkout-extensions" target="_blank">Click here</a> for a guide on how to add the app block to your store.</div>
          </div>
          <div className="actions">
            <button className="positive" onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.props.hideCheckoutExtensionModal();
            }}>Thanks, I understand</button>
            <button className="contact" onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              loadContactUsSurvey();
            }}>Questions? Contact us</button>
          </div>
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId] || {};
  const user = state.user || {};
  const misc = state.misc || {};

  return {
    user,
    accounts,
    account,
    showCheckoutExtensionModal: misc.showCheckoutExtensionModal
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShopifyCheckoutExtensionModal));