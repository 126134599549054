import React, { Component } from 'react';

import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import _ from 'lodash';

import * as PollActions from '../actions/Polls';
import * as RouterActions from '../actions/Router';

import { DragSource, DropTarget } from 'react-dnd';
import { Link } from 'react-router-dom';
import { encode, getSlideLabel, getSlideIcon, previewSlides, capitalize, stripTags } from '../utils';

function getInlineSymbol(action, captialize) {
  let content = action;
  if (captialize) {
    capitalize(action);
  }

  let icon = <i className="fas fa-diamond-turn-right" />
  if (action === 'end survey' || action === 'end') {
    icon = <i className="fas fa-stop" />
  }

  return <span className="action">{icon}{content}</span>;
}

class MoreActions extends Component {
  constructor(props) {
    super(props);
    this.state = { active: false };
  }

  render() {
    return (
      <span className={`more-actions ${this.props.idx === 0 ? 'first' : ''}`}>
        <div className="menu-toggle" onClick={() => this.setState({ active: !this.state.active })}><i className="fas fa-ellipsis-v" /></div>
        <div className="menu">
          <div>
            <div className="red" onClick={() => { this.props.delete(this.props._id) }}><i className="fas fa-trash" />Delete</div>
            {/*<div onClick={() => { this.props.quickEdit(this.props._id)} }><i className="fas fa-edit" />Quick Edit</div>*/}
            <div onClick={() => { this.props.duplicate(this.props.idx)}}><i className="fas fa-copy" />Duplicate</div>
            <div onClick={() => { this.props.navigateTo(`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/create?insertIdx=${this.props.idx}`)}}><i className="fas fa-angles-up" />Add Slide Above</div>
            <div onClick={() => { this.props.navigateTo(`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/create?insertIdx=${this.props.idx + 1}`)}}><i className="fas fa-angles-down" />Add Slide Below</div>
            <div onClick={() => { this.props.navigateTo(`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(this.props._id)}`)}}><i className="fas fa-edit" />Edit</div>
          </div>
        </div>
      </span>
    );
  }
}

function getSlideHandle(slides, slideId) {
  const match = _.find(slides, ({ _id }) => _id == slideId);
  if (match) {
    return match.handle;
  }
}

class Slide extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { connectDragPreview, connectDragSource, connectDropTarget, isDragging, isOver, type } = this.props;

    let isHidden = null;
    let nextSlide = null;

    if (this.props.settings.hidden) {
      isHidden = (<span className="slide-status" title="This slide is hidden."><i className="fas fa-eye-slash" /></span>);
    }

    let branchingRules = [];
    // let hasRules = this.props.nextSlide;
    // if (this.props.answers && previewSlides[type].answers) {
    //   this.props.answers.forEach(({ title, nextSlide }) => {
    //     if (nextSlide) {
    //       hasRules = true;

    //       if (nextSlide === -1) {
    //           branchingRules.push({ answer: title, action: 'end survey', nextSlide });
    //       }

    //       const nextSlideTitle = getSlideHandle(this.props.slides, nextSlide);
    //       if (nextSlideTitle) {
    //         branchingRules.push({ answer: title, action: nextSlide === -1 ? 'end survey' : 'skip to', nextSlideTitle, nextSlide });
    //       }
    //     }
    //   })
    // }

    // if (this.props.nextSlide) {
    //   if (this.props.nextSlide === -1) {
    //     branchingRules.push({ action: 'end survey', nextSlide: this.props.nextSlide })
    //   } else {
    //     const nextSlideTitle = getSlideHandle(this.props.slides, this.props.nextSlide);
    //     if (nextSlideTitle) {
    //       branchingRules.push({ action: 'skip to', nextSlideTitle, nextSlide: this.props.nextSlide })
    //     }
    //   }
    // }

    // if (hasRules) {
    //   nextSlide = (<span className="slide-status" title="This slide has branching rules."><i className="fas fa-code-branch" /></span>);
    // }

    // if (hasRules === -1) {
    //   nextSlide = (<span className="slide-status" title="The survey will terminate here if this slide is reached."><i className="fas fa-stop-circle" /></span>);
    // }

    // if (branchingRules.length && !this.props.logic) {
    //   branchingRules = <div
    //     className="branching-rules"
    //     // onMouseEnter={() => {
    //     //   console.log('entered branching rules');
    //     // }}
    //     // onMouseLeave={() => {
    //     //   console.log('left branching rules');
    //     // }}
    //   >{ branchingRules.map(({ answer, action, nextSlideTitle, nextSlide }, idx) => {
    //       if (answer) {
    //         return (<div
    //           onMouseEnter={() => {
    //             this.props.onMouseEnterBranch(action, nextSlide, this.props._id);
    //           }}
    //           onMouseLeave={() => {
    //             this.props.onMouseLeaveBranch(action, nextSlide, this.props._id);
    //           }}
    //         >If <strong>{ answer }</strong>{ getInlineSymbol(action) } <strong>{ nextSlideTitle }</strong></div>)
    //       }
    //       if (action === 'end survey') {
    //         return <div
    //           onMouseEnter={() => {
    //             this.props.onMouseEnterBranch(action, nextSlide, this.props._id);
    //           }}
    //           onMouseLeave={() => {
    //             this.props.onMouseLeaveBranch(action, nextSlide, this.props._id);
    //           }}
    //         >If this slide is reached <strong>{ getInlineSymbol(action) }</strong>.</div>            
    //       }
    //       return <div
    //         onMouseEnter={() => {
    //           this.props.onMouseEnterBranch(action, nextSlide, this.props._id);
    //         }}
    //         onMouseLeave={() => {
    //           this.props.onMouseLeaveBranch(action, nextSlide, this.props._id);
    //         }}
    //       >If <strong>any answer</strong>{ getInlineSymbol(action, true) } <strong>{ nextSlideTitle }</strong></div>
    //     })}
    //     </div>
    // } else 
    if (this.props.logic) {
      const logicContent = [];

      this.props.logic.forEach(({ rules, actions, type }) => {
        let block = { rules: [], actions: [] };

        if (!rules || !rules.length) { return; }
        rules.forEach(({ rule, ruleValue }) => {
          if (rule === 'submitted' && !type) {
            block.rules.push(<div>{ block.rules.length ? 'or' : 'If' } any answer</div>);
          }

          if (!ruleValue) { return; }

          if (rule === '=') {
            block.rules.push(<div>{ block.rules.length ? 'or' : 'If' } answer is <strong>{ruleValue}</strong></div>);
          }
          if (rule === 'includes-any') {
            block.rules.push(<div>{ block.rules.length ? 'or' : 'If' } answer includes any: <strong>{ruleValue.join(', ')}</strong></div>);
          }
          if (rule === 'includes-all') {
            block.rules.push(<div>{ block.rules.length ? 'or' : 'If' } answer includes all: <strong>{ruleValue.join(', ')}</strong></div>);
          }
          if (rule === 'excludes-any') {
            block.rules.push(<div>{ block.rules.length ? 'or' : 'If' } answer does not include any: <strong>{ruleValue.join(', ')}</strong></div>);
          }
          if (rule === 'excludes-all') {
            block.rules.push(<div>{ block.rules.length ? 'or' : 'If' } answer does not include all: <strong>{ruleValue.join(', ')}</strong></div>);
          }
          if (rule === 'contains') {
            block.rules.push(<div>{ block.rules.length ? 'or' : 'If' } answer contains: <strong>{ruleValue}</strong></div>);
          }
          if (rule === 'not-contains') {
            block.rules.push(<div>{ block.rules.length ? 'or' : 'If' } answer does not contain: <strong>{ruleValue}</strong></div>);
          }
          if (rule === '>') {
            block.rules.push(<div>{ block.rules.length ? 'or' : 'If' } answer is greater than: <strong>{ruleValue}</strong></div>);
          }
          if (rule === '>=') {
            block.rules.push(<div>{ block.rules.length ? 'or' : 'If' } answer is greater than or equal to: <strong>{ruleValue}</strong></div>);
          }
          if (rule === '<') {
            block.rules.push(<div>{ block.rules.length ? 'or' : 'If' } answer is less than: <strong>{ruleValue}</strong></div>);
          }
          if (rule === '<=') {
            block.rules.push(<div>{ block.rules.length ? 'or' : 'If' } answer is less than or equal to: <strong>{ruleValue}</strong></div>);
          }
        });

        let printedSkipAction = false;
        let keyword = 'Then';

        let hasBranchingLogic = false;
        this.props.logic.forEach((row) => {
          if (row.type === 'default') { return }
          row.actions.forEach(({ action }) => {
            if (['skip', 'end', 'continue'].indexOf(action) !== -1) {
              hasBranchingLogic = true;
            }
          })
        });

        if (type === 'default') {
          keyword = 'Otherwise';
          if (this.props.logic.length <= 1) {
            keyword = 'All answers will';
          }
          if (!hasBranchingLogic) {
            keyword = 'All answers will';
          }
        }

        actions.forEach(({ action, actionValue }) => {
          if (action === 'continue') {
            if (block.nextSlide) { return; }
            block.actions.push(<div>{ block.actions.length ? 'and' : <span><i className="fas fa-turn-up fa-rotate-90 turn-arrow" /> {keyword}</span> }<span className="action"><i className="fas fa-play" />continue to the next slide</span></div>)
            block.nextSlide = -2;
          }
          if (action === 'skip') {
            if (block.nextSlide) { return; }
            let nextSlideTitle = getSlideHandle(this.props.slides, actionValue);
            if (!nextSlideTitle) { nextSlideTitle = '[Slide Removed]' }

            block.actions.push(<div>{ block.actions.length ? 'and' : <span><i className="fas fa-turn-up fa-rotate-90 turn-arrow" /> {keyword}</span> }<span className="action"><i className="fas fa-diamond-turn-right" />skip to</span><strong>{ nextSlideTitle }</strong></div>)
            block.nextSlide = actionValue;
          }
          if (action === 'end') {
            if (block.nextSlide) { return; }
            block.actions.push(<div>{ block.actions.length ? 'and' : <span><i className="fas fa-turn-up fa-rotate-90 turn-arrow" /> {keyword}</span> }<span className="action"><i className="fas fa-stop" />end survey</span></div>);
            block.nextSlide = -1;
          }
          if (action === 'redirect') {
            block.actions.push(<div>{ block.actions.length ? 'and' : <span><i className="fas fa-turn-up fa-rotate-90 turn-arrow" /> Then</span> }<span className="action"><i className="fas fa-square-arrow-up-right" />redirect to</span><strong>"{ actionValue }"</strong></div>);
          }
          if (action === 'send-email') {
            const emailTemplates = this.props.account.emailTemplates || [];
            let template = _.find(emailTemplates, ({ id }) => { return id === actionValue});
            if (template) {
              block.actions.push(<div>{ block.actions.length ? 'and' : <span><i className="fas fa-turn-up fa-rotate-90 turn-arrow" /> Then</span> }<span className="action"><i className="fas fa-envelope" />send email</span><strong>"{ template.title || template.subject }"</strong></div>);
            }
          }
          if (action === 'send-notification-email') {
            const emailTemplates = this.props.account.emailTemplates || [];
            let template = _.find(emailTemplates, ({ id }) => { return id === actionValue});
            if (template) {
              block.actions.push(<div>{ block.actions.length ? 'and' : <span><i className="fas fa-turn-up fa-rotate-90 turn-arrow" /> Then</span> }<span className="action"><i className="fas fa-inbox" />send notification email</span><strong>"{ template.title || template.subject }"</strong></div>);
            }
          }
          if (action === 'generate-discount-code') {
            block.actions.push(<div>{ block.actions.length ? 'and' : <span><i className="fas fa-turn-up fa-rotate-90 turn-arrow" /> Then</span> }<span className="action"><i className="fas fa-percent" />generate a discount code</span><strong></strong></div>);
          }
          if (action === 'add-store-credit') {
            block.actions.push(<div>{ block.actions.length ? 'and' : <span><i className="fas fa-turn-up fa-rotate-90 turn-arrow" /> Then</span> }<span className="action"><i className="fas fa-credit-card" />add store credit</span><strong></strong></div>);
          }
          if (action === 'run-code') {
            block.actions.push(<div>{ block.actions.length ? 'and' : <span><i className="fas fa-turn-up fa-rotate-90 turn-arrow" /> Then</span> }<span className="action"><i className="fas fa-code" />run custom code</span><strong></strong></div>);
          }
          if (action === 'trigger-webhook') {
            block.actions.push(<div>{ block.actions.length ? 'and' : <span><i className="fas fa-turn-up fa-rotate-90 turn-arrow" /> Then</span> }<span className="action"><i className="fas fa-network-wired" />trigger webhook</span><strong>"{ actionValue }"</strong></div>);
          }
          if (action === 'add-customer-tag') {
            block.actions.push(<div>{ block.actions.length ? 'and' : <span><i className="fas fa-turn-up fa-rotate-90 turn-arrow" /> Then</span> }<span className="action"><i className="fas fa-tag" />add customer tag</span><strong>"{ actionValue }"</strong></div>);        
          }
          if (action === 'add-order-tag') {
            block.actions.push(<div>{ block.actions.length ? 'and' : <span><i className="fas fa-turn-up fa-rotate-90 turn-arrow" /> Then</span> }<span className="action"><i className="fas fa-tag" />add order tag</span><strong>"{ actionValue }"</strong></div>);
          }
          if (action === 'add-customer-metafield') {
            block.actions.push(<div>{ block.actions.length ? 'and' : <span><i className="fas fa-turn-up fa-rotate-90 turn-arrow" /> Then</span> }<span className="action"><i className="fas fa-cube" />add customer metafield</span><strong>"{ actionValue.split('|XX|').pop() }"</strong></div>);
          }
          if (action === 'add-order-metafield') {
            block.actions.push(<div>{ block.actions.length ? 'and' : <span><i className="fas fa-turn-up fa-rotate-90 turn-arrow" /> Then</span> }<span className="action"><i className="fas fa-cube" />add order metafield</span><strong>"{ actionValue.split('|XX|').pop() }"</strong></div>);
          }
        });

        logicContent.push(block);
      });

      branchingRules = <div
        className="branching-logic"
        // onMouseEnter={() => {
        //   console.log('entered branching rules');
        // }}
        // onMouseLeave={() => {
        //   console.log('left branching rules');
        // }}
      >{ logicContent.map((block) => <div
        onMouseEnter={() => {
          if (block.nextSlide) {
            this.props.onMouseEnterBranch(null, block.nextSlide, this.props._id);
          }
        }}
        onMouseLeave={() => {
          if (block.nextSlide) {
            this.props.onMouseLeaveBranch(null, block.nextSlide, this.props._id);
          }
        }}
      >
        <div className="logic-rules">{ block.rules.map((rule) => rule) }</div>
        <div className="logic-actions">{ block.actions.map((action) => action) }</div>
      </div>) }</div>
    } else {
      branchingRules = null;
    }

    let rewardBanner;
    if (this.props.type === 'reward' && this.props.rewardBanner) {
      rewardBanner = <div className="banner"><strong>Banner:</strong> <span>{ stripTags(this.props.rewardBanner) }</span></div>
    }

    let extraDetails = [];
    if (this.props.settings.optional) {
      extraDetails.push('optional');
    }
    if (this.props.settings.hidden) {
      extraDetails.push('hidden');
    }
    if (extraDetails.length) {
      extraDetails = <span>({extraDetails.join(', ')})</span>
    } else {
      extraDetails = null;
    }

    return connectDropTarget(<div key={this.props._id} className={`${isDragging ? 'dragging' : ''} ${isOver ? `is-over ${direction}` : ''} slide-item ${this.props.highlight ? 'highlight' : ''} ${this.props.lowlight ? 'lowlight' : ''}`}>
      <div className={`object-container ${this.props.active && !window.isDragging ? 'active' : ''}`} style={{position: 'relative'}}>
        <div className="idx">{this.props.idx + 1}</div>
        {connectDragPreview(<div className={`slide ${this.props.idx === 0 ? 'first' : ''} ${this.props.active && !window.isDragging ? 'active' : ''}`} onClick={() => this.props.onMouseEnter()}>
          {connectDragSource(<span className="handle" />)}

          <div className="up-down-arrows">
            <div className={`up ${this.props.idx === 0 ? 'disabled' : ''}`} onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (this.props.idx === 0) {
                return;
              }
              this.props.reorderSlides(this.props.idx-1, this.props.idx);
            }}>
              <i className="fas fa-chevron-up" />
            </div>
            <div className={`down ${this.props.idx === this.props.slides.length-1 ? 'disabled' : ''}`} onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (this.props.idx === this.props.slides.length-1) {
                return;
              }
              this.props.reorderSlides(this.props.idx+1, this.props.idx);
            }}>
              <i className="fas fa-chevron-down" />
            </div>
          </div>

          <div>
            <MoreActions
              idx={this.props.idx}
              _id={this.props._id}
              navigateTo={this.props.navigateTo}
              pollId={this.props.pollId}
              accountId={this.props.accountId}
              delete={this.props.delete}
              duplicate={this.props.duplicate}
              quickEdit={(idx) => {
                this.setState({ isQuickEdit: true });
              }}
            />
            { isHidden }
            <span className="edit-slide slide-status has-inline-tooltip no-animate" title="Edit this slide"><Link to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(this.props._id)}?tab=details`}><i className={`fas fa-edit`} /></Link><div className="tooltip-content">Edit this slide</div></span>
            { /* nextSlide */ }
            <i className={`icon fas ${getSlideIcon(type)}`} />
            <div>
              <Link className="slide-title" to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(this.props._id)}?tab=details`}>
                {this.props.handle || this.props.title || this.props._id}<i className="fas fa-edit" />
              </Link>
            </div>
            <div className="below">
              { rewardBanner }
              { getSlideLabel(type) }
              { extraDetails }
              { branchingRules }
            </div>
          </div>
        </div>)}
      </div>
    </div>);
  }
}

/* Drag */
const dragSource = {
  beginDrag(props) {
    window.isDragging = true;
    // props.onMouseLeave();

    return {
      idx: props.idx
    };
  },
  endDrag(props) {
    console.log('drag end');
    props.onMouseLeave();
    window.isDragging = false;
  }
};

const dragCollect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  }
};

let direction;
const dropTarget = {
  hover(props, monitor, component) {
    const dragIdx = monitor.getItem().idx;
    let dropIdx = props.idx;

    if (dragIdx < dropIdx) {
      direction = 'down';
      component.forceUpdate();
    } else if (dragIdx > dropIdx) {
      direction = 'up';
      component.forceUpdate();
    }
  },
  drop(props, monitor, component) {
    const dragIdx = monitor.getItem().idx;
    let dropIdx = props.idx;

    if (dragIdx === dropIdx) {
      return;
    }

    let idx;
    if (dragIdx < dropIdx) {
      idx = dropIdx - 1 < 0 ? 0 : dropIdx;
      props.reorderSlides(dragIdx, idx);
    } else {
      idx = dropIdx + 1 >= props.slideCount ? props.slideCount - 1 : dropIdx;
      props.reorderSlides(dragIdx, idx);
    }
  }
};

const dropCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver()
})

function mapStateToProps(state) {
  return {
    loading: state.polls.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PollActions, ...RouterActions }, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  DragSource('SLIDE', dragSource, dragCollect),
  DropTarget('SLIDE', dropTarget, dropCollect)
)(Slide);
