import React, { Component } from 'react';
import { encode } from '../utils';

class Notification extends Component {
  render() {
    return (
      <div className={`notification ${this.props.type}`}>
        <div className="content"
          dangerouslySetInnerHTML={{ __html : this.props.message }} 
        />
        <div 
          className="close"
          onClick={this.props.close}
        ><i className="fas fa-close" /></div>
      </div>
    );
  }
}

export default class HeaderNotifications extends Component {
  render() {
    let notification = null;
    let count = null;

    notification = <Notification
      message={`You have no slides in any of your surveys. To collect and analyze user responses you need to add a slide to one of your surveys or <a href='/a/${encode(this.props.accountId)}/p/create'>create a new survey from a template</a>. If you need help please <a target='_blank' href='https://www.zigpoll.com/contact'>get in touch</a>.`}
    />

    return (
    <div id="header-notifications" className={'active'}>
      { notification }
    </div>
    )
  }
}