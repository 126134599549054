import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { url } from '../settings';

import _ from 'lodash';
import qs from 'querystring';
import moment from 'moment';

import * as AccountActions from '../actions/Accounts';
import * as PollActions from '../actions/Polls';
import * as InsightsActions from '../actions/Insights';
import * as DashboardActions from '../actions/Dashboard';
import * as FlashNotificationActions from '../actions/FlashNotifications';

import ColumnLayout from '../components/ColumnLayout';
import ColumnLoading from './ColumnLoading';

import { PieChart, DonutChart, ColumnChart, NPSChart } from '../components/Charts';
import { AuthSubmitButton } from '../components/Buttons';

import SectionHeader from '../components/SectionHeader';
import LoadingIndicator from '../components/LoadingIndicator';
import DateRangeInput from '../components/DateRangeInput';
import PollInfo from '../components/PollInfo';
import SlideInfo from '../components/SlideInfo';
import ExportCsvModal from '../components/ExportCsvModal'

import { decode, getPoll, stripTags, getSlideIcon, getSlideLabel, numberWithCommas, renderNumber, getTimestamp, truncate, encode, getManualInsightLimit } from '../utils';

class PollCard extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps._id && (prevProps._id !== this.props._id)) {
      this.props.setIdx(0);
    }
  }

  next() {
    this.props.setIdx(this.props.idx + 1)
  }

  prev() {
    this.props.setIdx(this.props.idx - 1)
  }

  renderSlide() {
    const props = this.props;

    /* Poll Summary Card */
    if (this.props.idx === 0) {
      return (<PollInfo {...props} />)
    }

    const slide = this.props.slides[this.props.idx - 1];
    return (<SlideInfo hideDateRange={true} slide={slide} diffs={this.props.diffs} slideIdx={this.props.idx} key={this.props.slideIdx} slideCount={this.props.slides.length} accountId={this.props.accountId} pollId={this.props._id} />)
  }  

  render() {
    const loading = (
      <div className="poll-card loading">
        <div>
          <div className="poll-content">
            <div className="section">
              <p>Loading...</p>
            </div>
          </div>
        </div>
      </div>
    );

    if (this.props.slides && this.props.idx !== 0 && !this.props.slides[this.props.idx-1]) {
      this.props.setIdx(0);
      return loading;
    }

    if (!this.props._id) {
      return loading;
    }

    const props = this.props;

    let editLink = `/a/${encode(props.accountId)}/p/${encode(props._id)}?tab=general`;
    const isVisible = props.isVisible !== false;

    let next = null;

    if (this.props.idx !== props.slides.length) {
      next = (<button onClick={this.next.bind(this)} className="next">Next</button>);
    }

    let back = null;
    if (this.props.idx !== 0) {
      back = (<button onClick={this.prev.bind(this)} className="back">Back</button>);
    }

    if (this.props.idx !== 0) {
      editLink = `/a/${encode(props.accountId)}/p/${encode(props._id)}/s/${encode(this.props.slides[this.props.idx-1]._id)}?tab=details`
    }

    return <div
      className={`poll-card ${isVisible ? 'active' : 'inactive' }`}
    >
      <div>

        <div className="actions">
          { next }
          {/*<Link className="edit-link" to={editLink}>Edit {this.props.idx === 0 ? 'Survey' : 'Slide'}</Link>*/}
          { back }
        </div>

        <div className="poll-content">
          { this.renderSlide() }
        </div>

        <div className="actions footer">
          { next }
          { back }
        </div>
      </div>
    </div>
  }
};

class ChatMessages extends Component {
  render() {
    let messages = this.props.messages.map(({ type, className, content, loading }) => 
      <div className={`message ${type} ${className || ''} ${loading ? 'loading' : ''}`}><div className="content">{ content }</div></div>
    );

    return <div className="chat-messages">
      { messages.reverse() }
    </div>
  }
}

class QueryForm extends Component {
  onSubmit() {
    this.props.onSubmit()
  }

  render() {
    let placeholders = [
      "What is the most common way customers find us?",
      "What can we do to increase revenue?",
      "What social media channels are working for us?",
      "How can we increase traffic?",
      "What questions should we ask our customers?"
    ];

    return (
      <form
        className="chat-input"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();

          this.onSubmit();
        }}
      >
        <input
          type="text"
          name="query"
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.props.onChange(e);
          }}
          value={this.props.query}
          placeholder={placeholders[Math.floor(Math.random()*placeholders.length)]}
        />

        <div className="submit" onClick={(e) => this.onSubmit()}>Submit</div>
      </form>
    );
  }
}

class ChartPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pollId: props.pollId,
      slideId: props.slideId,
      query: '',
      idx: 0,
      dateRange: { 
        startDate: moment(getTimestamp(props.pollId)).startOf('day').toDate(),
        endDate: moment().endOf('day').toDate() 
      },
    };

    // if (!this.props.account && this.props.accountId) {
    //   this.props.fetchAccount(this.props.accountId);
    // }
    props.fetchAccountWithPolls(props.accountsId)
    .then(() => {
      const polls = this.props.account.polls || [];
      if (!polls.length) { return; }

      const pollId = this.state.pollId || polls[0]._id;

      const poll = getPoll(this.props.polls, pollId);
      let slide;
      if (!this.state.slideId) {
        const slides = poll.slides || [];
        slide = slides[0] || {};
      }

      const state = {
        pollId: poll._id,
        slideId: this.state.slideId || slide._id,
      }

      this.onChange(state);
    });
  }

  export() {
    this.setState({ showExportModal: true });
  }

  onChange(state) {
    const prevState = this.state;
    this.setState(state, () => {
      const pollId = this.state.pollId;
      const dateRange = this.state.dateRange;
      
      if (!pollId) { return; }

      if (prevState.pollId !== pollId) {
        this.props.fetchPollData(pollId, null);
        const dateRange = {
          startDate: moment(getTimestamp(pollId)).startOf('day').toDate(),
          endDate: moment().endOf('day').toDate()
        }
        this.setState({ dateRange });
      } else if (!_.isEqual(this.state.dateRange, prevState.dateRange)) {
        this.props.fetchPollData(pollId, dateRange);
      }
    });
  }

  renderNotReady() {
    return (
      <ColumnLayout
        title={`Insights`}
        className="account-responses"
        graphics={true}
      >
        <div style={{ minWidth: 1000 }}>
          <div className="section-header no-margin">
            <div className="top">
              <div className="section-subtitle">
                <span>
                  <i className="fas fa-wand-magic-sparkles" />Insights
                </span>
              </div>
            </div>

            <div style={{ lineHeight: '18px', fontSize: 16 }} className="section-description">Generate AI insights for your surveys.</div>
          </div>

          <div className="card empty-object-list responses">
            <h3>No Surveys Created Yet</h3>
            <p>Try creating a survey or making your existing surveys live in order to collect data and generate insights.</p>
          </div>
        </div>
      </ColumnLayout>
    );
  }

  render() {
    if (!this.props.account) {
      return <ColumnLoading />
    }
    if (!this.props.account.polls) {
      return <ColumnLoading />
    }
    if (this.props.account.polls.length === 0) {
      return this.renderNotReady();
    }

    const pollId = this.state.pollId || this.props.account.polls[0]._id;
    const poll = getPoll(this.props.polls, pollId);
    const slides = poll.slides || [];

    let slideBrief = null;
    const slideId = this.state.slideId;
    const slide = _.find(poll.slides, ({ _id }) => slideId === _id );

    let pollSelector = <div className="select-wrapper"><select
      value={this.state.pollId}
      onChange={(e) => {
        e.preventDefault();
        e.stopPropagation();

        const pollId = e.target.value;
        const poll = getPoll(this.props.polls, pollId);
        const slides = poll.slides || [];
        const slideId = slides[0] ? slides[0]._id : undefined;

        this.onChange({ pollId, slideId: slideId })
      }}
    >
      { this.props.account.polls.map((poll) => 
        <option value={poll._id}>{poll.title}</option>
      ) }
    </select></div>

    let slideSelector = <div className="select-wrapper"><select
    value={this.state.slideId}
    onChange={(e) => {
      e.preventDefault();
      e.stopPropagation();

      const slideId = e.target.value;
      this.onChange({ slideId, page: 0 })
    }}>
      { slides.map((slide) => 
        <option value={slide._id}>{stripTags(slide.handle || slide.title)}</option>
      ) }
      { slides.length === 0 && <option selected={true} value={''} disabled={true}>No slides exist</option>}
    </select></div>

    if (slide) {
      slideBrief = <div className="slide-brief">
        <i className="fas fa-quote-left" />
        <div className="title">{ stripTags(slide.title) }</div>
        { slide.copy && <div className="copy">{ stripTags(slide.copy) }</div> }
        <div className="type"><i className={`fas ${ getSlideIcon(slide.type) }`} />{ getSlideLabel(slide.type) }</div>
      </div>
    }

    let answers = [];
    if (slide) {
      answers = slide.answers.map(({ title, handle }) => title || handle);

      if (slide.type === 'email-capture') {
        answers = ["Email Capture"];
      }
      if (slide.type === 'form') {
        answers = ["Form Submission"];
      }
      if (slide.type === 'long-answer' || slide.type === 'short-answer') {
        answers = ["Open-Ended Submission"];
      }
    }

    let messages = [
      { type: 'system', content: 'Hello, please ask me anything!' },
    ];

    const insights = this.props.insights[this.state.slideId] || [];
    insights.forEach((insight) => {
      messages.push({ type: 'query', content: insight.query });
      messages.push({ type: 'system', className: insight.type, content: insight.content, loading: insight.loading });
    });

    const manualInsightLimit = getManualInsightLimit(this.props.account);

    return (
      <ColumnLayout title="Insights" graphics={true} >
        <div style={{ minWidth: 1000, maxWidth: 1800, margin: '0px auto', padding: '0 20px' }}>

          <div className="content-container">
            <div className="section-header no-margin wide left">
              <div className="top">
                <div className="section-subtitle">
                  <span>
                    <i className="fas fa-wand-magic-sparkles" />Insights
                  </span>
                </div>
              </div>
              <div className="section-description">
                Generating insights for survey { pollSelector }
              </div>
            </div>

            {/*{ slideBrief }*/}

            <div className="top-wrapper">
              <div className="date-range-container">
                <label>Adjust your date range below.</label>
                <DateRangeInput
                  startDate={this.state.dateRange.startDate}
                  endDate={this.state.dateRange.endDate}
                  onChange={(dateRange) => {
                    this.setState({ dateRange, hasChanged: true })
                  }}
                  popperPlacement={'bottom-end'}
                />
                <AuthSubmitButton
                  title="Submit" 
                  disabled={!this.state.hasChanged}
                  onClick={() => {
                    this.props.fetchPollWithDateRange(this.state.pollId, this.state.dateRange);
                  }}
                />
              </div>
            </div>

            <div>
              <div className="chat-box-container">
                <div className="chat-box">
                  <ChatMessages
                    messages={messages}
                  />
                  <QueryForm
                    query={this.state.query}
                    onChange={(e) => {
                      this.setState({ query: e.target.value });
                    }}
                    onSubmit={() => {
                      this.setState({ query: '' });
                      this.props.generateInsight(this.props.accountId, pollId, slideId, this.state.dateRange, this.state.query)
                    }}
                  />
                </div>
                <div className="insights-limit">
                  { manualInsightLimit - (this.props.account.manualInsightCount || 0) } insight credits left
                </div>
              </div>

              <div style={{ opacity: this.props.pageLoading ? .5 : 1, cursor: this.props.pageLoading ? 'not-allowed' : '' }}>
                { poll.slides && <PollCard
                  export={this.export.bind(this)}
                  key={pollId}
                  resetPollStats={this.props.resetPollStats}
                  account={this.props.account}
                  accountId={this.props.accountId}
                  diffs={this.props.diffs}
                  charts={this.props.charts}
                  setIdx={(idx) => { this.setState({ idx }) }}
                  idx={this.state.idx}
                  updateInsights={this.props.updateInsights}
                  fetchPollWithSlides={this.props.fetchPollWithSlides}
                  {...poll}
                /> }
              </div>
            </div>
          </div>
        </div>

        <LoadingIndicator loading={this.props.pageLoading} />

        <ExportCsvModal
          show={this.state.showExportModal}
          onCancel={() => this.setState({ showExportModal: false })}
          onConfirm={({ email, responsePollId, dateRange }) => {
            this.props.exportPollParticipants(email, responsePollId, dateRange);
          }}
          isInsightsPage={true}
          dashboardExport={true}
          title={'Export Survey'}
          subtitle={'This will export all participants who have ever interacted with the selected survey.'}
          copy={'The CSV will be sent to the email listed below. It can take up to 1 hour to recieve a CSV export. The duration will depend on the amount of data your account has accumulated.'}
          email={this.props.user.email}
        />
      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId] || {};
  const misc = state.misc || {};

  const allResponses = state.responses || [];
  const responses = allResponses[accountId];

  const params = qs.parse(ownProps.location.search);
  const pollId = decode(params.pollId) || decode(ownProps.match.params.pollId);
  const slideId = decode(params.slideId) || decode(ownProps.match.params.slideId);

  return {
    accountId,
    account,
    user: state.user,
    insights: state.insights,
    polls: account.polls || [],
    responses,
    pollId,
    slideId,
    loading: accounts.loading,
    pageLoading: misc.pageLoading,
    diffs: state.diffs,
    charts: state.charts,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PollActions, ...AccountActions, ...DashboardActions, ...InsightsActions, ...FlashNotificationActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartPage);
