import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import moment from 'moment';
import _ from 'lodash';

import ColumnLoading from './ColumnLoading';

import ColumnLayout from '../components/ColumnLayout';
import HeaderButtons from '../components/HeaderButtons';
import InactiveNotification from '../components/InactiveNotification';
import NoSlideNotification from '../components/NoSlideNotification';
import ReachedLimitNotification from '../components/ReachedLimitNotification';

import SlideInfo from '../components/SlideInfo';
import Tooltip from '../components/Tooltip';
import Diff from '../components/Diff';
import PollSelector from '../components/PollSelector';
import MonthlyCounter from '../components/MonthlyCounter';
import ExportCsvModal from '../components/ExportCsvModal'
import PollInfo from '../components/PollInfo';
import EventsTimelineChart from '../components/EventsTimelineChart.js';
import SurveyPreviewHeader from '../components/SurveyPreviewHeader';

import * as ParticipantsActions from '../actions/Participants';
import * as DashboardActions from '../actions/Dashboard';
import * as PollActions from '../actions/Polls';
import * as FlashNotificationActions from '../actions/FlashNotifications';

import { decode, encode, renderNumber, renderAccountTitle, truncate, getPollType, getTimestamp, generatePageDisplaySettings, getPollDisplayType, getDisplaySettings, loadContactUsSurvey } from '../utils';

import qs from 'query-string';
import $ from 'jquery';

import BrowserFrame from '../components/BrowserFrame';
import Embed from '../components/Embed';
import PagePreview from '../components/PagePreview';

window.$ = $;

function isActiveTrial(account) {
  if (!account.trialExpiration) { return false; }
  const now = moment().unix();
  return (now < account.trialExpiration);
}

class TrialCountdown extends Component {
  render() {
    let specialPrice = 50;
    if (this.props.account.promo.indexOf('75') !== -1) {
      specialPrice = 25;
    }
    let specialPlan = 'Pro';

    return (
      <div className="content-block trial-info">
        <div className='content active no-hover'>
          <div className="copy">
            <div className="top">✅ Your free trial is active.</div>
            <div className="bottom">Your free trial expires on <strong>{ moment.unix(this.props.account.trialExpiration).format('MM/DD/YYYY') }</strong>. After this date you will be charged <strong>${specialPrice}</strong> monthly for the <strong>{specialPlan}</strong> plan.</div>
          </div>
        </div>
      </div>
    );
  }
}

class DashboardVisualDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = { active: true };
  }

  componentDidMount() {
    $(window).on('resize.display', this.resize);
    this.resize();
    window.pollState = 'before-submission';
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showLink !== this.props.showLink) {
      this.resize();
    }

    if ((prevProps.active !== this.props.active) && this.props.active) {
      this.resize();
    }

    return null;
  }

  componentWillUnmount() {
    $(window).off('resize.display', this.resize)
  }

  resize() {
    $('.poll-card').css('min-height', $(window).height());
  }

  next(isFromAnswer) {
    if (isFromAnswer && this.props.idx === 0) {
      /* Do nothing */
      return;
    }
    if (this.props.idx === 0) {
      this.props.setIdx(1);      
    } else {
      this.props.setIdx(this.props.idx + 1);
    }
  }

  prev() {
    this.props.setIdx(this.props.idx - 1);
  }

  goto(slideId) {
    let idx = undefined;
    this.props.slides.forEach(({ _id }, i) => {
      if (_id === slideId) {
        idx = i;
      }
    })
    if (idx === undefined) { return this.next(); }

    this.props.setIdx(idx+1);
  }

  reset() {
    this.props.setIdx(0);
  }

  render() {
    let slides = this.props.slides || [];

    let title, subtitle, showTitle, showLoading;
    let renderSlidesWarning = (slides.length === 0) && !this.props.loading;
    let showEmbed = true;
    if (renderSlidesWarning) {
      subtitle = <div><i className="fas fa-info-circle warning"/>There are no slides for this survey.</div>
      showEmbed = false;
      showTitle = true;
    }
    if (this.props.loading) {
      showLoading = true;
      showEmbed = false;
    }

    let previewType = <Embed
      {...this.props}
      scalePoll={false}
      slides={slides}
      next={this.next.bind(this)}
      prev={this.prev.bind(this)}
      goto={this.goto.bind(this)}
      showHidden={true}
      currentIdx={this.props.idx > 0 ? this.props.idx - 1 : 0}
      pollState={window.pollState}
      isEmbed={this.props.pollType === 'embed'}
    />

    if (['widget', 'embed', 'api'].indexOf(this.props.pollType) === -1) {
      previewType = <PagePreview
        {...this.props}
        scalePoll={false}
        slides={slides}
        next={this.next.bind(this)}
        prev={this.prev.bind(this)}
        goto={this.goto.bind(this)}
        showHidden={true}
        currentIdx={this.props.idx > 0 ? this.props.idx - 1 : 0}
        pollState={window.pollState}
      />
    }

    let belowVisualDisplay = this.props.belowVisualDisplay || null;

    if (!showEmbed) {
      previewType = null;
    }

    return (
    <div className="visual-display-wrapper">
      <div className="visual-display" ref={this.el}>
        <BrowserFrame 
          title={title}
          subtitle={subtitle}
          showTitle={showTitle}
          showLoading={showLoading}
        />

        { previewType }
      </div>
      { belowVisualDisplay }
    </div>
    );
  }
}

class ContentSlider extends Component {
  constructor(props) {
    super(props);
    this.blocks = [
      {
        title: '🎁 Have you tried measuring how many customers are purchasing your product as a gift?',
        cta: 'Learn More',
        url: 'https://www.zigpoll.com/study/amaro-case-study',
      },
      {
        title: '💡 How one brand uses Zigpoll to build Klaviyo segments that increase open rates and reduce spam.',
        cta: 'Read more',
        url: 'https://www.zigpoll.com/study/jolly-mama-case-study',
      },
      { 
        title: '💡 Discover how a global brand uses surveys to build confidence in initiatives and improve attribution.',
        cta: 'Learn More',
        url: 'https://www.zigpoll.com/study/dalfilo-case-study',
      },
      {
        title: '🔄 Questions a Shark Tank company asks to launch 6-figure product lines and improve conversion.',
        cta: 'Learn More',
        url: 'https://www.zigpoll.com/study/kanga-coolers-case-study',
      },
      {
        title: '💡 Looking for inspiration? See what questions a 7-figure beauty brand asks pre purchase.',
        cta: 'Get Started',
        url: 'https://www.zigpoll.com/study/how-a-beauty-brand-collects-data-before-purchases ',
      },
      { 
        title: "🌎 Did you know you can auto-translate your survey into multiple languages?",
        cta: 'Get Started',
        url: 'https://www.zigpoll.com/resource/multi-language-translation',
      },
      { 
        title: "❓ Using checkout extensibility? Add our app block to your thank you and post purchase pages.",
        cta: 'Get Started',
        url: 'https://www.zigpoll.com/blog/zigpoll-shopify-checkout-extensions',
      },
      { 
        title: "✉️ Using Klaviyo? Try building a customer segment using data collected from Zigpoll.",
        cta: 'Get Started',
        url: 'https://www.zigpoll.com/blog/klaviyo-segment-zigpoll',
      },
      { 
        title: "☎️ Need assistance or have an feature request? Contact us any time for help.",
        cta: 'Reach Out',
        url: 'https://www.zigpoll.com/contact',
      },
      { 
        title: "🗑️ Need to remove some test data from your survey? Follow these steps to get it sorted.",
        cta: 'Learn More',
        url: 'https://www.zigpoll.com/blog/how-to-delete-responses',
      },
      { 
        title: "🤔 Wondering how your submissions are adding up? Get all the details here.",
        cta: 'Learn More',
        url: 'https://www.zigpoll.com/blog/how-responses-are-counted',
      },
      { 
        title: "✉️ Want to fully white label your survey experience? Try a custom sender and survey domain.",
        cta: 'Get Started',
        url: 'https://www.zigpoll.com/blog/how-to-setup-a-custom-sender-domain',
      },
      { 
        title: "🧠 Connect on LinkedIn and keep up on the best ways to utilize first party data for your business.",
        cta: 'Follow Along',
        url: 'https://www.linkedin.com/in/jason-zigelbaum'
      }
    ];
    this.blocks = _.shuffle(this.blocks);
    this.state = { idx: 0 };
  }

  prev(e) {
    e.preventDefault();
    e.stopPropagation();

    let idx = this.state.idx - 1;
    if (idx < 0) {
      idx = this.blocks.length - 1;
    }
    this.setState({ idx });
  }

  next(e) {
    e.preventDefault();
    e.stopPropagation();

    let idx = this.state.idx + 1;
    if (idx >= this.blocks.length) {
      idx = 0;
    }
    this.setState({ idx });
  }

  render() {
    return (
      <div className="content-block">
        <div onClick={(e) => this.prev(e)} className="prev"><i className="fas fa-chevron-left" /></div>
        <div onClick={(e) => this.next(e)} className="next"><i className="fas fa-chevron-right" /></div>
        { this.blocks.map((content, idx) => {
          return (
            <div className={`content ${idx === this.state.idx ? 'active' : ''}`} onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.open(content.url, '_blank');
            }}>
              <div className="copy">
                <div className="top">{ content.title }</div>
                <div className="bottom">{ content.cta }</div>
              </div>
            </div>
          );
        }) }
      </div>
    );
  }
}

class PromotedApps extends Component {
  hide() {
    window.localStorage.setItem('hide-promoted-apps', true);
    this.forceUpdate();
  }

  render() {
    // if (window.localStorage.getItem('hide-promoted-apps')) {
      return null;
    // }

    if (!this.props.account || !this.props.account.shop) {
      return null;
    }

    const plan = this.props.account.plan || {};
    if (plan.key !== 'lite') { return null; }

    return (<div className="promoted-apps-section">
      <div className="header">
        <div className="wrapper">
          <div className="close" onClick={this.hide.bind(this)}><i className="fas fa-close" /></div>
          <div className="top"><i className="fas fa-heart" />Recommended Shopify Apps</div>
        </div>
      </div>
      <div className="app-list">
        <div className="wrapper">
          <a href="https://pagefly.io?ref=zigpoll&target=app-listing" target="_blank" className="promoted-app">
            <img className="logo" src="https://cdn.shopify.com/app-store/listing_images/f85ee597169457da8ee70b6652cae768/icon/CKmsycCOx_YCEAE=.png" />
            <div className="content">
              <div className="title">PageFly Landing Page Builder</div>
              <div className="description">Drag and drop page builder to boost conversions for all pages</div>
            </div>
          </a>
          <a href="https://apps.shopify.com/smart-seo?utm_source=zigpoll&utm_medium=recommended+apps&utm_campaign=partnerships" target="_blank" className="promoted-app">
            <img className="logo" src="https://cdn.shopify.com/app-store/listing_images/8f4a21f1020c0448bf74213052d39b70/icon/CMDun-Oqk_cCEAE=.png" />
            <div className="content">
              <div className="title">Smart SEO</div>
              <div className="description">SEO, Page Speed, Image Optimization, JSON-LD</div>
            </div>
          </a>
          <a href="https://apps.shopify.com/trackipal?auth=1?utm_source=partner&utm_medium=zigpoll&utm_campaign=inappexchange&utm_content=appstorelisting&utm_term=june2023" target="_blank" className="promoted-app">
            <img className="logo" src="https://cdn.shopify.com/app-store/listing_images/973dc0555ecbd64c7fc4e0ee225d1781/icon/COHPiOnM5fwCEAE=.png" />
            <div className="content">
              <div className="title">TrackiPal PayPal Tracking Sync</div>
              <div className="description">Auto-sync PayPal tracking info for shipped orders.</div>
            </div>
          </a>
        </div>
      </div>
    </div>);
  }
}

class PollCard extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps._id && (prevProps._id !== this.props._id)) {
      this.props.setIdx(0);
    }
  }

  next() {
    this.props.setIdx(this.props.idx + 1)
  }

  prev() {
    this.props.setIdx(this.props.idx - 1)
  }

  renderSlide() {
    const props = this.props;

    /* Poll Summary Card */
    if (this.props.idx === 0) {
      return (<PollInfo {...props} />)
    }

    const slide = this.props.slides[this.props.idx - 1];
    return (<SlideInfo slide={slide} diffs={this.props.diffs} slideIdx={this.props.idx} slides={this.props.slides || []} key={this.props.slideIdx} slideCount={this.props.slides.length} accountId={this.props.accountId} pollId={this.props._id} />)
  }  

  render() {
    const loading = (
      <div className="poll-card loading">
        <div>
          <div className="poll-content">
            <div className="section">
              <p>Loading...</p>
            </div>
          </div>
        </div>
      </div>
    );

    if (this.props.slides && this.props.idx !== 0 && !this.props.slides[this.props.idx-1]) {
      this.props.setIdx(0);
      return loading;
    }

    if (!this.props._id) {
      return loading;
    }

    const props = this.props;

    let editLink = `/a/${encode(props.accountId)}/p/${encode(props._id)}?tab=general`;
    if (!props.slides || props.slides.length === 0) {
      editLink = `/a/${encode(props.accountId)}/p/${encode(props._id)}?tab=slides`;
    }
    const isVisible = props.isVisible !== false;

    let next = null;

    if (this.props.idx !== props.slides.length) {
      next = (<button onClick={this.next.bind(this)} className="next">Next</button>);
    }

    let back = null;
    if (this.props.idx !== 0) {
      back = (<button onClick={this.prev.bind(this)} className="back">Back</button>);
    }

    if (this.props.idx !== 0) {
      editLink = `/a/${encode(props.accountId)}/p/${encode(props._id)}/s/${encode(this.props.slides[this.props.idx-1]._id)}?tab=details`
    }

    return <div
      id="poll-card-anchor"
      className={`poll-card ${isVisible ? 'active' : 'inactive' }`}
    >
      <div>

        <div className="actions">
          { next }
          <Link className="edit-link" to={editLink}>Edit {this.props.idx === 0 ? 'Survey' : 'Slide'}</Link>
          { back }
        </div>

        <div className="poll-content">
          { this.renderSlide() }
        </div>

        <div className="actions footer">
          { next }
          { back }
        </div>
      </div>
    </div>
  }
};

function showAttributionSurvey(user) {
  setTimeout(() => {
    if (window.hasLoadedSurvey) { return; }
    if (window.Zigpoll.loaded) { return; }

    window.hasLoadedSurvey = true;
    window.Zigpoll.user = {
      id: user._id,
      metadata: {
        email: user.email,
        name: user.name,
        shop: user.shop
      }
    }
    window.Zigpoll.metadata = {
      email: user.email,
      name: user.name,
      shop: user.shop      
    }
    window.Zigpoll.pollId = '65ea03c4972db144b026ee5f';
    if (window.Zigpoll && window.Zigpoll.refresh) {
      window.Zigpoll.refresh();
    }
  }, 1000 * 30);
}

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = { hideHelpBlock: window.localStorage.getItem('zigpoll-hide-help-block') }
  }

  componentDidMount() {
    if (this.props.user.accounts.length) {
      this.props.fetchData(this.props.accountId).then(() => {      
        if (this.props.pollId && this.props.activePoll) {
          this.props.fetchPollData(this.props.pollId);
        }
      });
    } else {
      this.props.history.replace('/a/create');
    }
  }

  export() {
    this.setState({ showExportModal: true });
  }

  componentDidUpdate(prevProps) {
    if (!this.props.pollId && this.props.account && this.props.account.polls[0] && this.props.account.polls[0]._id) {
      return this.props.setPollId(this.props.account.polls[0]._id);
    }

    if (this.props.accountId !== prevProps.accountId) {
      return this.props.fetchData(this.props.accountId);
    }

    if (this.props.pollId && !this.props.activePoll && !this.props.polls.loading) {
      return this.props.fetchPollData(this.props.pollId);
    }

    if (this.props.pollId !== prevProps.pollId) {
      return this.props.fetchPollData(this.props.pollId);
    }
  }

  render() {
    let account;
    let loaded;

    if (this.props.accounts && this.props.user.accounts.length) {
      account = this.props.account;

      if (account && account.polls) {
        loaded = true;
      }
    }

    if (!loaded) {
      return <ColumnLoading />
    }

    if (this.props.user) {
      const user = this.props.user;
      const age = moment().diff(getTimestamp(user._id), 'days', true);
      // if (age > 3) {
        showAttributionSurvey(user);
      // }
    }

    let breadcrumbs = (
      <div id="breadcrumbs">
        <div className="wrapper">
          <ul className="crumbs">
            <li><Link to={`/a/${encode(this.props.account._id)}`} title='Account'>Account</Link></li>
          </ul>
        </div>
      </div>
    );

    breadcrumbs = null;

    const accountDiff = this.props.diffs[this.props.account._id] || {};
    const pollDashboardTitle = this.props.activePoll ? <span>{ getPollType(this.props.activePoll) }<strong>{truncate(this.props.activePoll.title, 45)}</strong><Tooltip>View the key statistics for both your survey and each of its individual slides. Click "next" to step through each slide in order.</Tooltip></span> : <span><div>&nbsp;</div></span>;

    let pageDisplaySettings = this.props.account.pageDisplaySettings || generatePageDisplaySettings(this.props);
    if (this.props.activePoll) {
      pageDisplaySettings = this.props.activePoll.pageDisplaySettings || generatePageDisplaySettings({ poll: this.props.activePoll });
    }

    let helpBlock = null;
    if (!this.state.hideHelpBlock) {
      helpBlock = <div className="help-block">
        <div className="close" onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          window.localStorage.setItem('zigpoll-hide-help-block', true);
          this.setState({ hideHelpBlock: true });
        }}><i className="fas fa-close"></i></div>
        <i className='icon far fa-circle-question'></i>
        <p className="title">Need help getting the most out of Zigpoll?</p>
        <p>Check out our <a href="https://www.zigpoll.com/resources" target="_blank">resources index</a>, <a href="https://www.linkedin.com/in/jason-zigelbaum" target="_blank">connect to stay up to date</a>, and <a href="https://www.zigpoll.com/contact" target="_blank" onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          loadContactUsSurvey(this.props.user);
        }}>contact us</a> for tips, details, and solutions that are tailored to your business.</p>
      </div>
    }

    let belowBlock = null;
    if (this.props.activePoll && this.props.activePoll._id) {
      belowBlock = <div className="below-visual-display"><i className="fas fa-arrow-turn-up" />A preview of the survey titled: <Link to={`/dashboard/a/${encode(this.props.accountId)}/p/${encode(this.props.activePoll._id)}`}>{this.props.activePoll.title}</Link> is printed above. <Link to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.activePoll._id)}?tab=slides`}>Click here</Link> to make edits or <a href={`/preview/${this.props.accountId}/${this.props.activePoll._id.toString()}`} target="_blank">click here to try a full demo</a>.</div>
    }


    let pollStats = (
      <div className="dashboard-container split big account-display">
        <div style={{ position: 'relative' }}>
          <PollSelector />

          {/*<div className="card-subtitle" style={{ marginLeft: 0, maxWidth: 'none' }}>{ pollDashboardTitle }</div>*/}

          <PollCard export={this.export.bind(this)} charts={this.props.charts} idx={this.props.idx} diffs={this.props.diffs} setIdx={this.props.setIdx} fetchPollWithSlides={this.props.fetchPollWithSlides} updateInsights={this.props.updateInsights} accountId={this.props.accountId} resetPollStats={this.props.resetPollStats} account={this.props.account} {...this.props.activePoll} />
        </div>
        <div className="preview-container-wrapper">
          <SurveyPreviewHeader 
            account={this.props.account}
            poll={this.props.activePoll}
          />
          <div className="preview-container">
            <DashboardVisualDisplay
              loading={!this.props.activePoll}
              pollId={this.props.pollId}
              pageDisplaySettings={pageDisplaySettings}
              slides={this.props.activePoll ? this.props.activePoll.slides : []}
              active={true}
              pollType={getPollDisplayType(this.props.activePoll)}
              toggleActive={() => this.setState({ launcherActive: !this.state.launcherActive })}
              idx={this.props.idx ? this.props.idx : 0 }
              setIdx={this.props.setIdx.bind(this)}
              belowVisualDisplay={<div>{belowBlock}{helpBlock}</div>}
              {...getDisplaySettings(this.props.account, this.props.activePoll)}
            />
          </div>
        </div>
      </div>
    );

    if (this.props.account.polls.length === 0) {
      pollStats = (
        <div>
          <div className="card empty-object-list" style={{ margin: '0px auto', maxWidth: 1800 }}>
            <h3>You haven't made any surveys</h3>
            <p>Your stats will show up here when you make some.</p>

            <Link to={`/a/${encode(this.props.accountId)}/p/create`} className="green">Create Survey</Link>
          </div>
        </div>
      );
    }

    let isInactive = true;
    let slidesCount;

    this.props.account.polls.forEach((poll) => {
      if (poll.isVisible) {
        isInactive = false;
      }
      if (poll.slides) {
        if (slidesCount === undefined) {
          slidesCount = 0;
        }
        slidesCount += poll.slides.length;
      }
    });

    let notification = null;
    if (slidesCount === 0) {
      notification = <NoSlideNotification accountId={this.props.accountId} />      
    }
    if (isInactive) {
      notification = <InactiveNotification accountId={this.props.accountId} />
    }

    const submissionLimit = this.props.account.plan.submissionLimit;
    const submissionCount = this.props.account.submissionCount;
    if (submissionCount >= submissionLimit) {
      notification = <ReachedLimitNotification accountId={this.props.accountId} />
    }

    return (
      <ColumnLayout
        title="Dashboard"
        graphics={false}
        breadcrumbs={breadcrumbs}
        headerNotification={notification}
      >
        <div style={{ minWidth: 1000, maxWidth: 1800, margin: '0px auto', padding: '0 10px' }}>
          <div className="account-stats">

            {/*
            <div className="card-subtitle center"><i className="fas fa-folder-open" />{ truncate(this.props.account.title, 40) }<Tooltip bottom={true} zIndex={5}>These numbers are for your account as a whole. If you want to see how each survey is performing, check below.</Tooltip></div>
            */}

            <div className="stats-wrapper">
              <div className="stats">
                <div className="response-count">
                  <label><div className="pill">Open-Ended</div>Responses<Tooltip>All the open-ended responses you have received under this account.</Tooltip></label>
                  <Link to={`/responses/a/${encode(this.props.account._id)}`}><p title={this.props.account.responseCount || 0}>{ renderNumber(this.props.account.responseCount, '--') }<Diff value={accountDiff.responsesDiff} /></p></Link>
                </div>
                <div className="vote-count">
                  <label><div className="pill">fixed-choice</div>Responses<Tooltip>Total number of fixed-choice responses submitted to this account.</Tooltip></label>
                  <Link to={`/responses/a/${encode(this.props.account._id)}`}><p title={this.props.account.voteCount || 0}>{ renderNumber(this.props.account.voteCount, '--') }<Diff value={accountDiff.votesDiff} /></p></Link>
                </div>
                <div className="email-count">
                  <label>Emails<Tooltip>All the email addresses you have collected with this account.</Tooltip></label>
                  <Link to={`/emails/a/${encode(this.props.account._id)}`}><p title={this.props.account.emailCount || 0}>{ renderNumber(this.props.account.emailCount, '--') }<Diff value={accountDiff.emailsDiff} /></p></Link>
                </div>
                <div className="participant-count">
                  <label>Participants<Tooltip>All the participants who have responded to any survey under this account.</Tooltip></label>
                  <Link to={`/participants/a/${encode(this.props.account._id)}`}><p title={this.props.account.participantCount || 0}>{ renderNumber(this.props.account.participantCount, '--') }<Diff value={accountDiff.participantsDiff} /></p></Link>
                </div>
              </div>

              { isActiveTrial(this.props.account) ? <TrialCountdown user={this.props.user} account={this.props.account} /> : <ContentSlider /> }
            </div>
          </div>

          <div className="stats-bar">
            <MonthlyCounter inline={true} account={this.props.account} hideHeader={true} user={this.props.user} />

            { <EventsTimelineChart 
                className="column-chart chart wide"
                smallDots={true}
                height={100}
                showLoading={true}
                title={<div className="card-subtitle center"><span><i className="fas fa-chart-line" />Account Engagement<Tooltip>This graph shows the number of submissions all of your surveys combined have recieved each day.</Tooltip></span></div>}
                data={ this.props.charts[this.props.account._id] || 'loading' } /> }
          </div>

          { pollStats }
        </div>

        <PromotedApps account={this.props.account} />

        <HeaderButtons>
          <button
            onClick={this.export.bind(this)}
            disabled={this.props.account.polls.length === 0 ? true : false}
          >Export to CSV</button>
          <Link className="create" to={`/a/${encode(this.props.accountId)}/p/create`}>New Survey</Link>
        </HeaderButtons>

        <ExportCsvModal
          show={this.state.showExportModal}
          onCancel={() => this.setState({ showExportModal: false })}
          onConfirm={({ email, responsePollId, dateRange }) => {
            this.props.exportPollParticipants(email, responsePollId, dateRange);
          }}
          dashboardExport={true}
          title={'Export Survey'}
          subtitle={'This will export all participants who have ever interacted with the selected survey.'}
          copy={'The CSV will be sent to the email listed below. It can take up to 1 hour to recieve a CSV export. The duration will depend on the amount of data your account has accumulated.'}
          email={this.props.user.email}
        />
      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];
  // const pollId = state.dashboard.pollId;

  const params = qs.parse(ownProps.location.search);
  const idx = parseInt(params.idx) || 0;
  const pollId = decode(params.id);

  return {
    account,
    accounts,
    accountId,
    pollId,
    activePoll: state.polls[pollId],
    polls: state.polls,
    user: state.user,
    diffs: state.diffs,
    charts: state.charts,
    idx
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...PollActions, ...DashboardActions, ...FlashNotificationActions, ...ParticipantsActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
