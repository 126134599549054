import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Tooltip from '../components/Tooltip';
import Diff from '../components/Diff';
import EventsTimelineChart from '../components/EventsTimelineChart';
import DateRangeToggle from '../components/DateRangeToggle';

import moment from 'moment';
import _ from 'lodash';

import DeleteConfirm from './DeleteConfirm';

import { getName } from 'country-list';

import { encode, renderNumber, getTargetingSettings, getVisibilitySettings, getTemplateFromDisplayRules, prepareShopifyCustomerTargeting, capitalizeFirstLetter, customerTargetingOptions, getDisplaySettings, getDateAsTimestamp, getTimestamp, prepareInsights, getShareDomain } from '../utils';


export default class PollInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { showMoreInsights: false, insightIdx: 0 };
  }

  renderInsights() {
    const poll = this.props;
    const insights = prepareInsights(poll.insights);

    if (!insights || insights.length === 0) {
      if (this.state.hasUsedDateRange) {
        return (<div className="section">
          <DateRangeToggle
            key={this.props._id}
            pollId={this.props._id}
            fetchPoll={this.props.fetchPollWithSlides}
            onSubmit={(dateRange) => {
              this.props.updateInsights(this.props.accountId, this.props._id, dateRange);
              this.setState({ hasUsedDateRange: true })
            }}
          />
          <div className="empty-object-list no-neg-margin">
            <h3>No Data Found For This Range.</h3>
            <p>Please select another date range and try again.</p>
          </div>
        </div>);        
      }
      return null;
    }

    const insight = insights[this.state.insightIdx] || {};
    let { content } = insight;
    const items = content;

    let showMore = (
      <div
        className="show-more"
        onClick={() => {
          this.setState({ showMoreInsights: !this.state.showMoreInsights });
        }}
      >{ !this.state.showMoreInsights ? 'Show more' : 'Show less' }</div>
    );

    if (items.length <= 4) {
      showMore = null;
    }

    let next = <div className="next" onClick={() => this.setState({ insightIdx: ++this.state.insightIdx })}><i className="fas fa-arrow-right" /></div>;
    let prev = <div className="prev" onClick={() => this.setState({ insightIdx: --this.state.insightIdx })}><i className="fas fa-arrow-left" /></div>

    let hasNext = true;
    let hasPrev = true;
    if (this.state.insightIdx === 0) {
      prev = (<div className="prev disabled"><i className="fas fa-arrow-left" /></div>);
      hasPrev = false;
    }
    if (this.state.insightIdx >= poll.insights.length - 1) {
      next = (<div className="next disabled"><i className="fas fa-arrow-right" /></div>);
      hasNext = false;
    }
    let nextPrev = null;
    if (hasNext || hasPrev) {
      nextPrev = (<span className="next-prev">{ prev }{ next }</span>);
    }

    return (<div className="insights-container section">
      <div className="clearfix">
        <DateRangeToggle
          key={this.props._id}
          pollId={this.props._id}
          fetchPoll={this.props.fetchPollWithSlides}
          onSubmit={(dateRange) => {
            this.props.updateInsights(this.props.accountId, this.props._id, dateRange);
            this.setState({ hasUsedDateRange: true })
          }}
        />
      </div>
      <div className={`insights ${items.length > 3 ? 'has-more' : ''}`}>
        <p className="stats"><span className="title"><i className="fas fa-wand-magic-sparkles" />Survey Insights</span><span>{ getDateAsTimestamp(insight._id, 'MMMM Do YYYY, h:mm A') }</span>{nextPrev}</p>
        <ul>
        { items.map((item, idx) => {
          if ((idx >= 4) && !this.state.showMoreInsights) {
            return null;
          }
          return <li>{ item }</li>
        }) }
        </ul>
        { showMore }
      </div>
    </div>)
  }

  render() {
    const props = this.props;
    if (!props.settings) { return; }

    const poll = props;
    const isVisible = props.isVisible !== false;

    let status = <p className="active status">Visible</p>
    if (!isVisible) {
      status = <p className="inactive status">Hidden</p>
    }
    if (props.isArchived) {
      status = <p className="archived status">Archived</p>
    }

    let isEmail = null;
    let isSms = null;
    if (poll.settings.shopifyWebhooks || poll.settings.shopifyAbandonedCheckout) {
      isEmail = true;
      if (poll.settings.sms) {
        isSms = true;
      }
    }
    let pollIcon = <i className="fas fa-poll" title="This an on-site type survey." />
    if (poll.settings.selector) {
      pollIcon = <i className="fas fa-arrows-to-dot" title="This an embedded survey." />
    }
    if (poll.settings.apiOnly) {
      pollIcon = <i className="fas fa-link" title="This survey only visible via a public link." />
    }
    if (poll.settings.apiOnly && poll.settings.pollType === 'api') {
      pollIcon = <i className="fas fa-link" title="This survey only visible via a public link and API calls." />
    }
    if (isEmail) {
      isEmail = <i className="fas fa-envelope" title="This survey is sent via email." />;      
      pollIcon = <i className="fas fa-envelope" title="This survey is sent via email." />
      if (isSms) {
        isEmail = <i className="fas fa-sms" title="This survey is sent via SMS." />;      
        pollIcon = <i className="fas fa-sms" title="This survey is sent via SMS." />
      }
    }
    const template = getTemplateFromDisplayRules(poll.pageRules, poll.pageExcludeRules, true);
    if (template) {
      pollIcon = <i className={`fas ${template.icon}`} title={template.title} />
    }

    let displayRules = <p>{ pollIcon }Show on <strong>all pages</strong></p>
    if (this.props.pageRules.length) {
      displayRules = <span>
        <p className="subheader">Appear on the following pages:</p>
        <ul>{this.props.pageRules.map((rule,idx) => <li key={idx}>{rule === '' || rule === '/' ? 'Homepage' : rule}</li>)}</ul>
      </span>;
    }

    let pageExcludeRules = null;
    if (this.props.pageExcludeRules && this.props.pageExcludeRules.length) {
      pageExcludeRules = <span>
        <p className="subheader" style={{marginTop: 5 }}>Exclude the following pages:</p>
        <ul>{this.props.pageExcludeRules.map((rule,idx) => <li key={idx}>{rule === '' || rule === '/' ? 'Homepage' : rule}</li>)}</ul>
      </span>
    }

    const targetingSettings = getTargetingSettings(this.props.account, this.props);
    const visibilitySettings = getVisibilitySettings(this.props.account, this.props);

    const percentage = targetingSettings.percentage;
    let targetingItems = [`Show <strong>${percentage || 100}%</strong> of the time to:`];

    if (Object.keys(targetingSettings).length) {
      const { referral, geography, language, technology, ip, visitors, pageViews, metadata } = targetingSettings;

      targetingItems = [
        `Show <strong>${percentage || 100}%</strong> of the time to:`
      ];

      if (referral) {
        const domains = referral.domains || [];
        if (referral.type === 'direct') {
          targetingItems.push('Visitors who went directly to the site');
        }
        if (referral.type === 'search-engine') {
          targetingItems.push('Visitors who came from a search engine');
        }
        if (referral.type === 'only') {
          targetingItems.push(`Visitors who came from: <strong>${domains.join(', ')}</strong>`);
        }
        if (referral.type === 'except') {
          targetingItems.push(`Visitors who did not come from: <strong>${domains.join(', ')}</strong>`);
        }
        if (referral.type === 'paid') {
          targetingItems.push(`Visitors who came from paid ads`);
        }
        if (referral.type === 'organic') {
          targetingItems.push(`Visitors who came organically`);
        }
      }
      if (geography) {
        const countries = geography.countries || [];
        if (geography.type === 'only') {
          targetingItems.push(`Visitors from: <strong>${countries.map((code) => getName(code)).join(', ')}</strong>`); 
        }
        if (geography.type === 'except') {
          targetingItems.push(`Visitors who are not from: <strong>${countries.map((code) => getName(code)).join(', ')}</strong>`);
        }
      }
      if (language) {
        const langs = language.languages || [];
        if (language.type === 'only') {
          targetingItems.push(`Visitors with language set to: <strong>${langs.map((code) => code).join(', ')}</strong>`); 
        }
        if (language.type === 'except') {
          targetingItems.push(`Visitors with language not set to: <strong>${langs.map((code) => code).join(', ')}</strong>`);
        }
      }
      if (technology) {
        const techs = technology.technologies || [];
        if (technology.type === 'only') {
          targetingItems.push(`Visitors using: <strong>${techs.join(', ')}</strong>`); 
        }
        if (technology.type === 'except') {
          targetingItems.push(`Visitors who are not using: <strong>${techs.join(', ')}</strong>`);
        }
      }
      if (ip) {
        const ipAddresses = ip.ipAddresses || [];
        if (ip.type === 'only') {
          targetingItems.push(`Visitors with IP: <strong>${ipAddresses.join(', ')}</strong>`); 
        }
        if (ip.type === 'except') {
          targetingItems.push(`Visitors without IP: <strong>${ipAddresses.join(', ')}</strong>`);
        }
      }
      if (visitors) {
        if (visitors.type === 'returning') {
          targetingItems.push(`<strong>Returning visitors</strong>`); 
        }
        if (visitors.type === 'first-time') {
          targetingItems.push(`<strong>First time visitors</strong>`);
        }
      }
      if (metadata) {
        const entries = metadata.entries || [];
        if (metadata.type === 'only') {
          targetingItems.push(`When metadata contains: <strong>${entries.map(({ key, value }) => `${key} = ${value}`).join(' and ')}</strong>`); 
        }
        if (metadata.type === 'except') {
          targetingItems.push(`When metadata does not contain: <strong>${entries.map(({ key, value }) => `${key} = ${value}`).join(' and ')}</strong>`);
        }
      }
      if (pageViews && (pageViews > 1)) {
        targetingItems.push(`Visitors who have viewed <strong>${pageViews}</strong> pages`)
      }
    };

    // if (this.props.settings.shopifyCustomerTargeting) {
    //   if (this.props.settings.shopifyCustomerTargeting === 'new-customers') {
    //     targetingItems.push(`New customers only`);
    //   }
    //   if (this.props.settings.shopifyCustomerTargeting === 'existing-customers') {
    //     targetingItems.push(`Existing customers only`);
    //   }
    // }

    if (this.props.settings.shopifyCustomerTargeting) {
      const shopifyCustomerTargeting = prepareShopifyCustomerTargeting(this.props.settings.shopifyCustomerTargeting);

      const match = _.find(customerTargetingOptions, ({ value }) => _.isEqual(value, shopifyCustomerTargeting || [[],[]]));
      if (match) {
        targetingItems.push(`<strong>${match.label}</strong`);
      } else {
        shopifyCustomerTargeting[0].forEach((row, idx) => {
          // if (idx !== 0) {
          //   targetingItems.push('OR');
          // }
          row = [ ...row ];

          if (row[0] === 'tags') {
            row[0] = 'customer_tags';
          }
          if (row[1] === 'includes') {
            row[1] = 'includes_all';
          }
          let val = row.join(' → ').replace('lt', 'less than').replace('gt', 'greater than').replace(/_/g, ' ').replace(/,/g, ', ');
          targetingItems.push(capitalizeFirstLetter(val));
        });

        if (shopifyCustomerTargeting[1].length) {
          targetingItems.push('<strong>AND</strong>');

          shopifyCustomerTargeting[1].forEach((row, idx) => {
            // if (idx !== 0) {
            //   targetingItems.push('OR');
            // }
            row = [ ...row ];

            if (row[0] === 'tags') {
              row[0] = 'customer_tags';
            }
            if (row[1] === 'includes') {
              row[1] = 'includes_all';
            }
            const val = row.join(' → ').replace('lt', 'less than').replace('gt', 'greater than').replace('_', ' ').replace(/,/g, ', ');
            targetingItems.push(capitalizeFirstLetter(val));
          });
        }
      }
    }

    if (visibilitySettings.renderOnExitIntent) {
      targetingItems.push('Show on Exit Intent.');

      if (visibilitySettings.exitIntentCondition) {
        const condition = visibilitySettings.exitIntentCondition;
        if (condition === 'cartOnly') {
          targetingItems.push('Show to customers with at least one item in their cart.');
        }
        if (condition === 'emptyCartOnly') {
          targetingItems.push('Show to customers with no items in their cart.');
        }
        if (condition === 'customCartRules') {
          targetingItems.push('Show to customers based on custom conditions.');
        }
      }
    }

    if (targetingItems.length === 1) {
      targetingItems = [`Show to <strong>${percentage || 100}%</strong> of visitors`];
    }

    let targetingRules = <ul dangerouslySetInnerHTML={{ __html: 
      targetingItems.map((item) => `<li>${item}</li>`).join('')
    }}>
    </ul>

    let targetingBlock = (
      <div className="inline">
        <label>Targeting Rules<Tooltip>View the targeting filters applied to this survey. Targeting filters help give you control over when a survey is displayed.</Tooltip></label>
        { targetingRules }
      </div>
    );

    if (template) {
      displayRules = <p>{ pollIcon }<strong>{ template.shortTitle || template.title }</strong></p>;
      pageExcludeRules = null;
    }

    let isLinkOnly = this.props.settings && this.props.settings.apiOnly;
    if (this.props.settings && this.props.settings.apiOnly) {
      const link = <a href={`${getShareDomain(this.props.account)}${encode(this.props.accountId)}/${encode(this.props._id.toString())}`} target="_blank">public link<i className="fas fa-external-link" /></a>
      displayRules = <p><i className="fas fa-link"/>Only visible from a { this.props.isVisible ? link : 'public link'}</p>
      if (this.props.settings.pollType === 'api') {
        displayRules = <p><i className="fas fa-code"/>Only visible from API calls and this { this.props.isVisible ? link : 'public link'}</p>      
      }
    }

    isEmail = false;
    isSms = false;
    if (this.props.settings && this.props.settings.shopifyWebhooks) {
      isEmail = true;
      isSms = this.props.settings.sms;

      let emailDelay = this.props.settings.emailDelay || 1;
      let emailDelayInterval = this.props.settings.emailDelayInterval || 'hours';
      emailDelayInterval = emailDelayInterval.slice(0, -1);

      if (this.props.settings.shopifyWebhooks[0] === 'orders/paid') {
        displayRules = <p><i className={`fas ${isSms ? 'fa-sms' : 'fa-envelope '}`}/>{isSms ? 'Send SMS' : 'Email'} <strong>{emailDelay} { emailDelayInterval }{ emailDelay != 1 ? 's' : ''}</strong> after an order is <strong>paid</strong></p>;
      } else if (this.props.settings.shopifyWebhooks[0] === 'orders/fulfilled') {
        displayRules = <p><i className={`fas ${isSms ? 'fa-sms' : 'fa-envelope '}`}/>{isSms ? 'Send SMS' : 'Email'} <strong>{emailDelay} { emailDelayInterval }{ emailDelay != 1 ? 's' : ''}</strong> after an order is <strong>fulfilled</strong></p>;
      } else if (this.props.settings.shopifyWebhooks[0] === 'fulfillment_events/create') {
        displayRules = <p><i className={`fas ${isSms ? 'fa-sms' : 'fa-envelope '}`}/>{isSms ? 'Send SMS' : 'Email'} <strong>{emailDelay} { emailDelayInterval }{ emailDelay != 1 ? 's' : ''}</strong> after an order is <strong>delivered</strong></p>;
      } else if (this.props.settings.shopifyWebhooks[0] === 'orders/cancelled') {
        displayRules = <p><i className={`fas ${isSms ? 'fa-sms' : 'fa-envelope '}`}/>{isSms ? 'Send SMS' : 'Email'} <strong>{emailDelay} { emailDelayInterval }{ emailDelay != 1 ? 's' : ''}</strong> after an order is <strong>cancelled</strong></p>;
      }
    }
    if (this.props.settings && this.props.settings.shopifyAbandonedCheckout) {
      isEmail = true;
      let emailDelay = this.props.settings.emailDelay || 1;
      let emailDelayInterval = this.props.settings.emailDelayInterval || 'hours';
      emailDelayInterval = emailDelayInterval.slice(0, -1);

      displayRules = <p><i className="fas fa-envelope"/>Email <strong>{emailDelay} { emailDelayInterval }{ emailDelay != 1 ? 's' : ''}</strong> after <strong>abandoned checkout</strong></p>;
    }

    if (isLinkOnly && ((isEmail || isSms) && targetingItems.length <= 1)) {
      targetingBlock = null;
    }

    const pollDiff = this.props.diffs[this.props._id] || {};

    let responseRate = 0;
    let engagementRate = 0;
    let completionRate = 0;

    if (this.props.engagementCount !== undefined && this.props.presentationCount !== undefined) {
      // let presentationCount = this.props.presentationCount * .75;
      let presentationCount = this.props.presentationCount || 1;

      /* Need to cap at 100 if adjusted */
      engagementRate = (this.props.engagementCount / presentationCount) * 100;
      responseRate = ((this.props.replyCount || 0) / presentationCount) * 100;
      // completionRate = ((this.props.completionCount || 0) / presentationCount) * 100;
      completionRate = ((this.props.completionCount || 0) / this.props.engagementCount) * 100;
    }

    if (completionRate > 100) {
      completionRate = 100;
    }

    let showEngagementStats = false;
    if (this.props.engagementCount) {
      showEngagementStats = true;
    }

    let preview = (<a target='_blank' rel="noopener noreferrer" title="Preview your survey" className="poll-preview" href={`/preview/${this.props.accountId}/${this.props._id.toString()}`}><i className="fas fa-external-link-alt" />Preview</a>)
    preview = null;

    // if (this.props.slides && this.props.slides.length === 0) {
    //   preview = (<a target='_blank' rel="noopener noreferrer" title="Preview your survey" className="disabled poll-preview bottom" href={`/preview/${this.props.accountId}/${this.props.pollId}`} onClick={(e) => { e.preventDefault(); e.stopPropagation() }}><i className="fas fa-external-link-alt" />Preview<div className="content"><div>You need to add at least one slide before you can preview your survey.</div></div></a>)
    // }

    let hideCount = null;

    const displaySettings = getDisplaySettings(this.props.account, this.props.poll);
    if (!displaySettings.hideLauncher) {
      hideCount = (
        <div className="inline inline-block third">
          <label>Hide Count<Tooltip>The amount of times that the survey has been manually hidden.</Tooltip></label>
          <p className="hide-count" title={props.hideCount || 0}><span>{ renderNumber(props.hideCount) }<Diff value={pollDiff.hideCountDiff} /></span></p>
        </div>
      );
    }

    /* Only show for surveys created after 2023 */
    let viewCount = null;
    if (getDateAsTimestamp(poll._id, 'YYYY') >= 2023) {
      viewCount = (
        <div className="inline inline-block third">
          <label>View Count<Tooltip>The amount of unique times that the survey has been viewed across the web.</Tooltip></label>
          <p className="view-count" title={props.presentationCount || 0}><span>{ renderNumber(props.presentationCount) }<Diff value={pollDiff.presentationCountDiff} /></span></p>
        </div>
      );
    }

    let emailOpenRateStats = null;
    let smsOpenRateStats = null;

    if (this.props.outboundEmailOpenCount) {
      let deliveryCount = this.props.outboundEmailCount || 1;
      let openRate = (this.props.outboundEmailOpenCount / deliveryCount) * 100;

      if (openRate > 100) {
        openRate = 100;
      }

      emailOpenRateStats = (
        <div className="engagement">
          <div className="padding flex">

            <div className="inline inline-block half">
              <label>Email Send Count<Tooltip>The number of times this survey has been sent out to customers.</Tooltip></label>
              <p className="email-send-count" title={renderNumber(deliveryCount)}><span>{ renderNumber(deliveryCount) }</span></p>
            </div>

            <div className="inline inline-block half">
              <label>Email Clickthrough Rate<Tooltip>The % of customers who have viewed the survey once received via email.</Tooltip></label>
              <p className="email-open-rate" title={renderNumber(openRate) + '%'}><span>{ renderNumber(openRate) }%</span></p>
            </div>
          </div>
        </div>
      );
    }

    if (this.props.outboundSmsOpenCount) {
      let deliveryCount = this.props.outboundSmsCount || 1;
      let openRate = (this.props.outboundSmsOpenCount / deliveryCount) * 100;

      if (openRate > 100) {
        openRate = 100;
      }

      smsOpenRateStats = (
        <div className="engagement">
          <div className="padding flex">

            <div className="inline inline-block half">
              <label>SMS Send Count<Tooltip>The number of times this survey has been sent out to customers.</Tooltip></label>
              <p className="sms-send-count" title={renderNumber(deliveryCount)}><span>{ renderNumber(deliveryCount) }</span></p>
            </div>

            <div className="inline inline-block half">
              <label>SMS Clickthrough Rate<Tooltip>The % of customers who have viewed the survey once received.</Tooltip></label>
              <p className="sms-open-rate" title={renderNumber(openRate) + '%'}><span>{ renderNumber(openRate) }%</span></p>
            </div>
          </div>
        </div>
      );
    }

    /* Dont show if survey created before Nov 16th (openrates will be incorrect) */
    const pollCreationDate = getTimestamp(poll._id).getTime();
    const allowOpenRateStats = 1700158099309; /* Nov 16th @ 1:08PM ET */
    if (pollCreationDate < allowOpenRateStats) {
      emailOpenRateStats = null;
      smsOpenRateStats = null;
    }

    let resetStats = null;
    if (pollCreationDate > moment().subtract(2, 'days').valueOf()) {
      resetStats = <div className="reset-stats" onClick={() => {
        this.setState({ showConfirmModal: true });
      }}>Reset</div>
    }

    return (
      <div>
        <div className="section no-padding">
          <div className="padding">
            {/*<p className="section-title">Survey Information</p>*/}

            <div className="inline" style={{position: 'relative'}}>
              { preview }
              <label>Title</label>
              <p>{ this.props.title } { resetStats }</p>
            </div>

            <div className="inline" style={{position: 'relative' }}>
              <label>Format<Tooltip>This explains where and when your survey will appear.</Tooltip></label>
              { displayRules }
              { pageExcludeRules }
            </div>


            { targetingBlock }

            <div className="inline">
              <label>Status<Tooltip>Only visible surveys will be displayed to your visitors.</Tooltip></label>
              { status }
            </div>

            <div>
              { <div className="inline timeline-chart">
              <label>Engagement</label>
              <EventsTimelineChart
                  data={ this.props.charts[this.props._id] || 'loading' } 
                  isWeekly={true}
                  showLoading={true}
                  className="chart inline" 
                  height={100}
                /></div> }
            </div>
          </div>

          { this.renderInsights() }

          <div className="engagement top">
            <div className="padding flex">
              <div className="inline inline-block third">
                <label>Open Count<Tooltip>The amount of times that the survey has been activated across the web.</Tooltip></label>
                <p className="open-count" title={props.openCount || 0}><span>{ renderNumber(props.openCount) }<Diff value={pollDiff.openCountDiff} /></span></p>
              </div>

              <div className="inline inline-block third">
                <label>Close Count<Tooltip>The amount of times that the survey has been activated and then closed across the web.</Tooltip></label>
                <p className="close-count" title={props.closeCount || 0}><span>{ renderNumber(props.closeCount) }<Diff value={pollDiff.closeCountDiff} /></span></p>
              </div>

              { hideCount }

            </div>
          </div>

          <div className="participants">
            <div className="padding flex">
              { viewCount }

              <Link className="link" to={`/participants/a/${encode(this.props.accountId)}/p/${encode(this.props._id)}`}><div className="inline inline-block half"><label>Participants<Tooltip>The number of unique individuals who responded to this survey.</Tooltip></label><p className="participant-count" title={props.participantCount || 0}><span>{renderNumber(props.participantCount)}<Diff value={pollDiff.participantsDiff} /></span></p></div></Link>

              <div className="inline inline-block half">
                <label>Completions<Tooltip>The amount of times that the survey has been completed from start to finish.</Tooltip></label>
                <p className="complete-count" title={props.completeCount || 0}><span>{ renderNumber(props.completeCount) }<Diff value={pollDiff.completeCountDiff} /></span></p>
              </div>
            </div>
          </div>

          { showEngagementStats && 
          <div className="engagement">
            <div className="padding flex">

              <div className="inline inline-block half">
                <label>Engagement Rate<Tooltip>The number survey engagements divided by the number of times the survey has been presented.</Tooltip></label>
                <p className="engagement-rate" title={renderNumber(engagementRate) + '%'}><span>{ renderNumber(engagementRate) }%</span></p>
              </div>

              <div className="inline inline-block half">
                <label>Response Rate<Tooltip>The number participant replies divided by the number of times the survey has been presented.</Tooltip></label>
                <p className="reply-rate" title={renderNumber(responseRate) + '%'}><span>{ renderNumber(responseRate) }%</span></p>
              </div>

              <div className="inline inline-block half">
                <label>Completion Rate<Tooltip>The number survey completions divided by the number of times the survey has been engaged with.</Tooltip></label>
                <p className="completion-rate" title={renderNumber(completionRate) + '%'}><span>{ renderNumber(completionRate) }%</span></p>
              </div>
            </div>
          </div>
          }

          { emailOpenRateStats }
          { smsOpenRateStats }

          <div className="reports">
            <div>
              <label>Want more details & insights?</label>
              <Link className="link" to={`/reports/a/${encode(this.props.accountId)}/p/${encode(this.props._id)}`}>View reports <i className="fas fa-arrow-right-long" /></Link>
            </div>
            <div>
              <label>Want to analyze this offline?</label>
              <div className="link" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                
                this.props.export();
              }}>Download CSV <i class="fa-solid fa-file-arrow-down"></i></div>
            </div>
          </div>
        </div>

        <DeleteConfirm 
          title="Are you sure?"
          subtitle={<span>This will reset the stats and response data for your survey back to zero.</span>}
          show={this.state.showConfirmModal}
          onConfirm={() => {
            console.log('Doing the reset');
            console.log(this.props);
            this.props.resetPollStats(poll._id);
          }}
          onCancel={() => {
            this.setState({ showConfirmModal: false });
          }}
        />

      </div>
    );
  }
}
